import { LogoutOutlined, ShoppingOutlined, UserOutlined } from '@ant-design/icons'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useHasSuperCard } from 'hooks/supercard_hooks'
import { SC_ORIGIN } from 'utils/constants'
// import VoucherIcon from 'public/icons/voucher-icon.png'

export default function Container({ children }) {
  const router = useRouter()
  const hasSuperCard = useHasSuperCard()

  const [activeMenu, setActiveMenu] = useState(0)
  const [isCartEmpty, setIsCartEmpty] = useState(false)
  const [cartBadge, setCartBadge] = useState(0)
  const [basePath, setBasePath] = useState('')
  const [drawer, setDrawer] = useState(false)
  const [me, setMe] = useState(null)
  const [showUpdateTcModal, setShowUpdateTcModal] = useState(false)
  const [updatedTermsVersion, setUpdatedTermsVersion] = useState(null)

  const state = useStoreState(s => ({
    me: s.me,
    logoutState: s.logoutState,
    carts: s.carts,
    cartItems: s.cartItems,
    socialMediaToVerify: s.socialMediaToVerify,
    scCardsV2: s.scCardsV2,
    updateMeState: s.updateMeState,
    // latest active sc
    latestActiveSc: s.latestActiveSc,
    acceptLegalTerms: s.acceptLegalTerms,
    latestAcceptedTerms: s.latestAcceptedTerms,
    legalTermsVersion: s.legalTermsVersion,
    updatedAcceptedTerms: s.updatedAcceptedTerms
  }))

  const actions = useStoreActions(a => ({
    setMe: a.setMe,
    getMe: a.getMe,
    logout: a.logout,
    setLogoutState: a.setLogoutState,
    getCarts: a.getCarts,
    getCartItems: a.getCartItems,
    setCartItems: a.setCartItems,
    scGetCards: a.scGetCards,
    updateMe: a.updateMe,
    setUpdateMeState: a.setUpdateMeState,
    // supercard v2
    getScCardsV2: a.getScCardsV2,
    setScCardsV2: a.setScCardsV2,
    // latest active sc
    setLatestActiveSc: a.setLatestActiveSc,
    postUpdateAcceptedTerms: a.postUpdateAcceptedTerms,
    setUpdatedAcceptedTerms: a.setUpdatedAcceptedTerms,
    getLegalTermsVersion: a.getLegalTermsVersion,
    getLatestAcceptedTerms: a.getLatestAcceptedTerms,
    setLatestAcceptedTerms: a.setLatestAcceptedTerms,
    setSocialMediaToVerify: a.setSocialMediaToVerify
  }))

  const menuItems = [
    {
      base: 'order-now',
      title: 'Order now!',
      link: '/order-now/categories/more'
    },
    {
      base: 'catalog',
      title: 'Menu',
      link: '/catalog/categories/more'
    },
    {
      base: 'promos',
      title: 'Promos',
      link: '/promos'
    },
    {
      base: 'order-tracker',
      title: 'Order Tracker',
      link: '/my-orders'
    },
    {
      base: 'supercard',
      title: 'Supercard',
      link: hasSuperCard ? '/supercard/my' : '/supercard/buy'
    }
  ]

  const accountItems = [
    {
      title: 'My Profile',
      code: 'my-profile',
      icon: <UserOutlined />,
      action: () => {
        router.push('/account')
      }
    },
    {
      title: 'My Vouchers',
      code: 'my-vouchers',
      icon: <img src='/icons/voucher-icon.svg' alt='' style={{ marginRight: 8 }} />,
      action: () => {
        router.push('/my-vouchers')
      }
    },
    {
      title: 'My Orders',
      code: 'my-orders',
      icon: <ShoppingOutlined />,
      action: () => {
        router.push('/my-orders')
      }
    },
    {
      title: 'Log out',
      code: 'log-out',
      icon: <LogoutOutlined />,
      action: () => handleLogout()
    }
  ]

  const handleLogout = () => {
    setMe(null)
    localStorage.clear()
    sessionStorage.clear()
    actions.logout()
    actions.setMe({ status: 0, payload: null })
  }

  const handleCheckCartItems = () => {
    if (cartBadge === 0) {
      setIsCartEmpty(true)
    } else {
      router.push('/checkout')
    }
  }

  useEffect(() => {
    if (!state.socialMediaToVerify) {
      actions.getMe()
    }
    actions.getLegalTermsVersion()
    setBasePath(router.pathname.split('/')[1])

    return () => {
      actions.setMe({ status: 0, payload: null })
      actions.setLogoutState({ status: 0, payload: null })
    }
  }, [])

  useEffect(() => {
    const { status } = state.updatedAcceptedTerms

    if (status === 2) {
      setShowUpdateTcModal(false)
      actions.setLatestAcceptedTerms({ status: 0, payload: null })
      // actions.setUpdatedAcceptedTerms({ status: 0, payload: null })
    }
  }, [state.updatedAcceptedTerms])

  // side effect to clear cache mechanism on catalog
  useEffect(() => {
    const { pathname } = router
    if (String(pathname.split('/')[1]) !== 'catalog') {
      if (localStorage.getItem('menuIndex') !== null) {
        localStorage.removeItem('menuIndex')
      }
      if (localStorage.getItem('menuSlug') !== null) {
        localStorage.removeItem('menuSlug')
      }
    }
  }, [router])

  useEffect(() => {
    const { status, payload } = state.me
    if (status === 2) {
      if (payload.details) {
        const { details } = payload
        actions.getLatestAcceptedTerms()
        if (payload.details.mobile_info && !sessionStorage.getItem('fetchedScItems') && state.scCardsV2.status === 0) {
          actions.getScCardsV2({ Channel: SC_ORIGIN })
        }

        if (!payload.details.mobile) {
          actions.setSocialMediaToVerify({
            first_name: details.first_name,
            last_name: details.last_name,
            email: details.email
          })
          router.push('/auth/register')
        }
        setMe(details)
        actions.getCarts()
      }
    } else if (status === 3) {
      setMe(null)
      actions.setMe({ status: 0, payload: null })
    }
  }, [state.me])

  useEffect(() => {
    const { status, payload } = state.carts
    if (status === 2) {
      setCartBadge(0)
      if (payload.count > 0) {
        actions.getCartItems(payload.results[0].id)
        // if (payload.results[0].voucher_amount && payload.results[0].voucher_code) {
        //   actions.getVouchers()
        // }
      }
    }
  }, [state.carts])

  useEffect(() => {
    const { status, payload } = state.cartItems
    if (status === 2) {
      setCartBadge(payload.length)
    }
  }, [state.cartItems])

  useEffect(() => {
    const { status } = state.logoutState
    if (status === 2) {
      router.push('/auth/login')
      actions.setLogoutState({ status: 0, payload: null })
    } else if (status === 3) {
      actions.setLogoutState({ status: 0, payload: null })
    }
  }, [state.logoutState])

  useEffect(() => {
    const { status } = state.updateMeState

    if (status === 2 && !state.socialMediaToVerify) {
      actions.getMe()
      // actions.setUpdateMeState({ status: 0, payload: null })
    } else if (status === 3) {
      actions.setUpdateMeState({ status: 0, payload: null })
    }
  }, [state.updateMeState])

  useEffect(() => {
    const {
      status: latestAcceptedTermsStatus,
      payload: latestAcceptedTermsPayload
    } = state.latestAcceptedTerms
    const {
      status: legalTermsVersionStatus,
      payload: legalTermsVersionPayload
    } = state.legalTermsVersion
    // if restircted on specific pages
    const notRestricted = !['/terms-and-conditions', '/privacy-policy', '/auth/login', '/auth/register', '/auth/verify-otp'].includes(window?.location.pathname)
    // if guest acccepted the previous terms
    const acceptedPreviousTerms = Boolean(
      latestAcceptedTermsStatus === 2 &&
      legalTermsVersionStatus === 2 &&
      parseFloat(latestAcceptedTermsPayload.version) < parseFloat(legalTermsVersionPayload.version))

    if (latestAcceptedTermsStatus === 3 || acceptedPreviousTerms) {
      if (notRestricted) {
        setShowUpdateTcModal(true)
        setUpdatedTermsVersion(legalTermsVersionPayload?.version)
      }
    }
  }, [state.latestAcceptedTerms, state.legalTermsVersion])

  useEffect(() => {
    const { status, payload } = state.latestActiveSc

    if (me) {
      // excluded update when user is on registration journey to prevent conflict with state managers
      if (status === 2 && !me.has_supercard && payload?.Cards?.length && !router.asPath.includes('/auth')) {
        actions.updateMe({
          birthdate: payload.BirthDate,
          has_supercard: true,
          sc_expiration: payload.Cards[0].ExpirationDate
        })
        actions.setLatestActiveSc({ status: 0, payload: null })
      } else if (
        (status === 3 && me.has_supercard === true) ||
        (status === 2 && !me.has_supercard && !payload.Cards.length) ||
        (status === 2 && me.has_supercard && !payload.Cards.length)
      ) {
        actions.updateMe({
          has_supercard: false,
          sc_expiration: null
        })
        actions.setLatestActiveSc({ status: 0, payload: null })
      }
    }
  }, [state.latestActiveSc, me, state.updateMeState])

  const acceptLegalTerms = () => {
    if (updatedTermsVersion) {
      actions.postUpdateAcceptedTerms({ version: updatedTermsVersion })
    }
  }

  return children({
    state,
    cartBadge,
    basePath,
    menuItems,
    activeMenu,
    setActiveMenu,
    me,
    drawer,
    setDrawer,
    handleLogout,
    accountItems,
    handleCheckCartItems,
    isCartEmpty,
    setIsCartEmpty,
    showUpdateTcModal,
    setShowUpdateTcModal,
    acceptLegalTerms
  })
}
